const types = require('../Editable/types');

const EDITABLE_FAKE_CHILDREN = [
  { type: 'large' },
  { type: 'small' },
  { type: 'small' },
  { type: 'large' },
];

const getDiscoveryProps = (props) => {
  const { componentId, title, theme, behavior, categories } = props;
  return {
    type: types.DISCOVERY,
    payload: { componentId, title, theme, behavior, categories },
  };
};

module.exports = { EDITABLE_FAKE_CHILDREN, getDiscoveryProps };
