const React = require('react');
const PropTypes = require('prop-types');

const Item = require('../item');

class GridRecommendationsBase extends React.Component {
  constructor(props) {
    super(props);

    this.extendedItems = props.items.map(item => ({
      ...item,
      lazyLoad: props.lazyLoad,
      metadata: props.metadata,
      restClient: props.restClient,
    }));
    this.buildGrid(props.itemsPerRow);
  }

  buildGrid(itemsPerRow) {
    this.grid = [];
    let totalRows = Math.floor(this.extendedItems.length / itemsPerRow);
    totalRows += ((this.extendedItems.length % itemsPerRow) > 0) ? 1 : 0;
    for (let i = 0; i < totalRows; i += 1) {
      const row = this.extendedItems.slice(i * itemsPerRow, itemsPerRow + (i * itemsPerRow));
      this.grid[i] = row; // eslint-disable-line security/detect-object-injection
    }
  }
}

GridRecommendationsBase.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  itemsPerRow: PropTypes.number.isRequired,
};

module.exports = GridRecommendationsBase;
