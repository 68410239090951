/**
 * Module dependencies
 */
const React = require('react');

const PropTypes = require('prop-types');
const Head = require('react-declarative-head');
const serialize = require('serialize-javascript');
const classNames = require('classnames');

const injectI18n = require('nordic/i18n/injectI18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const MeliGA = require('nordic/analytics/meli-ga');
const Script = require('nordic/script');
const Style = require('nordic/style');

const GoogleSearchConsole = require('../utils/GoogleSearchConsole');
const canUseDOM = require('../../../utils/enabledDOM');

const MetadataProvider = require('../../../components/metadataProvider');
const RenderTree = require('../../../appearance/RenderTree');

const AppearanceService = require('../../../../services/appearance');

const { THEMES_WITH_TRANSPARENT_HEADER } = require('../../../utils/constants/themeConfig');
const { HOME } = require('../../../utils/constants/sections');

const { useEffect } = React;
/**
 * HomeView Component
 */
function HomeView({
  i18n,
  translations,
  site,
  siteId,
  lowEnd,
  deviceType,
  company,
  shopModel,
  csrfToken,
  config,
  editable,
  section,
  allowMetrics,
}) {
  // eslint-disable-next-line
  const { appearance, fiscal_data, shop, contact} = shopModel;
  const { currency, title } = appearance.properties;
  const { siteKey, restClient } = config;
  const appearanceService = new AppearanceService(appearance);
  const layoutTheme = appearanceService.getComponentProperty('Document', 'theme');
  const metaTags = appearanceService.getComponentProperty('Document', 'meta_tags');
  const opacity = appearanceService.getComponentProperty('Header', 'opacity');
  const isTransparent = (opacity !== undefined) && (opacity !== 1);

  const homeClass = classNames(
    'home',
    `home--${layoutTheme}`,
    {
      'home--with-transparency': THEMES_WITH_TRANSPARENT_HEADER.includes(layoutTheme) && isTransparent,
      'home--with-payment': appearanceService.hasComponent('PaymentMethods'),
      'home--with-flash': appearanceService.getComponentProperty('Header', 'flash'),
      'home--with-banner': appearanceService.hasComponent('Banner'),
      'home--with-padding-bottom': !appearanceService.hasComponent('ShoppingInfo'),
      'home--padding-top-content': (section !== HOME),
    },
  );

  const serializableProps = {
    translations,
    site,
    siteId,
    lowEnd,
    deviceType,
    company,
    shopModel,
    csrfToken,
    config,
    editable,
    section,
    allowMetrics,
  };

  const customDimensions = { collectorId: shopModel.shop.id };
  const googleSearchConsoleId = GoogleSearchConsole.getContent(siteId);
  const page = (section === HOME) ? 'MAIN' : section.toUpperCase();

  useEffect(() => {
    if (canUseDOM() && window.tracking_mediator) {
      window.tracking_mediator(
        'dispatch',
        `page:${window.__PRELOADED_STATE__.section}`,
        window.__PRELOADED_STATE__.shopModel.appearance,
      );
      window.tracking_mediator(
        'dispatch',
        `gtm:${window.__PRELOADED_STATE__.section}`,
        { page, section },
      );
    }
  }, []);

  return (
    <div className={homeClass}>
      { (!editable && allowMetrics)
        && [
          <MeliGA
            siteId={siteId}
            platform="MS"
            section="HOME"
            page={`/${page}/`}
            dimensions={customDimensions}
          />,
        ]}

      <Head>
        <title>{title}</title>
        { googleSearchConsoleId ? <meta name="google-site-verification" content={googleSearchConsoleId} /> : null }
        { (metaTags && metaTags.length) ? metaTags.map((meta) => <meta name={meta.type} content={meta.content} />) : null }
      </Head>

      <Style href={`home-${deviceType}${editable ? '-editable' : ''}.css`} />
      <Script>
        {`
          window.__PRELOADED_STATE__ = ${serialize(serializableProps, { isJSON: true }) || {}};
        `}
      </Script>
      <script type="text/javascript" src="https://cdn.polyfill.io/v3/polyfill.min.js?features=es5,es6&flags=gated" />
      <Script src="vendor.js" />
      <Script src={`home-${deviceType}${editable ? '-editable' : ''}.js`} />

      <I18nProvider i18n={i18n}>
        <MetadataProvider
          metadata={{
            currency,
            deviceType,
            siteId,
            lowEnd,
            csrfToken,
            siteKey,
            restClient,
            fiscalData: fiscal_data,
            contact,
            socialNetworks: shop.social_networks,
            layoutTheme,
            shopModel,
          }}
        >
          <RenderTree node={appearance} i18n={i18n} />
        </MetadataProvider>
      </I18nProvider>
    </div>
  );
}

HomeView.propTypes = {
  i18n: PropTypes.shape().isRequired,
  translations: PropTypes.shape(),
  site: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  shopModel: PropTypes.shape(),
  csrfToken: PropTypes.string.isRequired,
  config: PropTypes.shape().isRequired,
  editable: PropTypes.bool,
  section: PropTypes.string.isRequired,
  allowMetrics: PropTypes.bool.isRequired,
};

HomeView.defaultProps = {
  translations: {},
  site: 'MS',
  shopModel: {
    appearance: {
      properties: {},
    },
  },
  editable: false,
};

/**
 * Inject i18n context as props into DemoView.
 */
module.exports = injectI18n(HomeView);
