const PropTypes = require('prop-types');
const React = require('react');
const classNames = require('classnames');
const global = require('../../../global');
const FormatPriceService = require('./../../../services/formatPrice');
const withTracker = require('../with-tracker');

const metadataPropTypes = PropTypes.shape({
  decimal_places: PropTypes.number.isRequired,
  decimal_separator: PropTypes.string.isRequired,
  thousand_separator: PropTypes.string.isRequired,
});

function withItemBehavior(WrappedComponent) {
  class WithItemBehavior extends React.Component {
    constructor(props) {
      super(props);
      const { decimal_places: decimalPlaces, thousand_separator: thousandSeparator } = props.metadata;
      const priceDigits = Math.floor(props.price.value).toString().length;
      const discount = FormatPriceService.getDiscount(props, decimalPlaces, thousandSeparator);
      const className = classNames(props.className, {
        [`price-digits-${priceDigits}`]: discount,
        'price-text': props.price.text,
        'trigger-item': props.is_trigger,
        'with-actions': props.actions.length > 0,
        'with-discount': discount,
        'with-installments': props.installments,
        'with-attributes': props.attributes,
      });

      this.bookmark = this.bookmark.bind(this);
      this.restorePreviousBookmark = this.restorePreviousBookmark.bind(this);
      this.onFinishFetching = this.onFinishFetching.bind(this);
      this.switchBookmark = this.switchBookmark.bind(this);
      this.itemHover = this.itemHover.bind(this);
      this.isFetching = false;
      this.mappedProps = {
        ...props,
        className,
        discount,
        showTitle: props.squared,
        image: props.picture,
        // props.attributes are handle by /ui-item/item.
        installments: props.installments || null,
        price: {
          ...props.price,
          symbol: props.price.currency_symbol,
          fraction: FormatPriceService.getThousandSeparated({ price: props.price.value, separator: thousandSeparator }),
          cents: FormatPriceService.getDecimalPart(props.price.value, decimalPlaces),
          decimal_separator: props.metadata.decimal_separator, // Not displayed, but needed for microdata,
          original_price: props.price.original_price && FormatPriceService
            .getThousandSeparated({
              price: props.price.original_price,
              separator: thousandSeparator,
            }),
          price: FormatPriceService.getThousandSeparated({ price: props.price.original_price, separator: thousandSeparator }),
        },
        shipping: props.shipping && FormatPriceService.getShipping(props.shipping),
        url: props.permalink,
        onFavClick: this.bookmark,
      };

      this.state = {
        bookmarked: props.bookmarked,
        singleLineTitleClassName: '',
      };
    }

    onFinishFetching() {
      this.isFetching = false;
    }

    switchBookmark(prevState) {
      return {
        bookmarked: !prevState.bookmarked,
      };
    }

    bookmark(e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.isFetching) {
        this.isFetching = true;
        this.setState(this.switchBookmark, this.updateBookmark);
      }
    }

    updateBookmark() {
      const verb = this.state.bookmarked ? 'post' : 'delete';
      this.props.restClient[verb](`/bookmarks/${this.props.id}`, {
        loginParams: { loginType: 'favorite', item_id: this.props.id },
        params: { go: global.location.href },
      })
        .then(this.onFinishFetching)
        // Restore the previous bookmark state if the endpoint failed.
        .catch(this.restorePreviousBookmark);
    }

    restorePreviousBookmark() {
      this.setState(this.switchBookmark, this.onFinishFetching);
    }

    itemHover() {
      if (this.title) {
        const itemDescriptionHeight = this.title.clientHeight;
        this.isSingleLineDescription(itemDescriptionHeight);
      }
    }

    isSingleLineDescription(height) {
      if (height <= 18) {
        this.setState({
          singleLineTitleClassName: 'with-single-line-description',
        });
      }
    }

    render() {
      this.mappedProps.bookmarked = this.state.bookmarked;
      const { singleLineTitleClassName } = this.state;
      const className = classNames(this.mappedProps.className, singleLineTitleClassName);
      const wrapperClassNames = className && className.split(/\s+/).map(name => `${name}__wrapper`);
      return (
        <div
          className={classNames('ui-item__wrapper', wrapperClassNames)}
          onFocus={this.itemHover}
          onMouseOver={this.itemHover}
        >
          <WrappedComponent
            {...this.mappedProps}
            className={className}
            titleRef={(title) => { this.title = title; }}
          />
        </div>
      );
    }
  }

  WithItemBehavior.itemPropTypes = {
    actions: PropTypes.array,
    bookmarked: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    is_trigger: PropTypes.bool,
    permalink: PropTypes.string.isRequired,
    picture: PropTypes.shape({
      title: PropTypes.string,
      src: PropTypes.string,
      src2x: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      className: PropTypes.string,
    }).isRequired,
    price: PropTypes.shape({
      currency_id: PropTypes.string,
      currency_symbol: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.number,
    }).isRequired,
    shipping: PropTypes.shape({
      free_shipping: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    }),
    squared: PropTypes.bool,
  };

  WithItemBehavior.defaultProps = {
    actions: [],
    bookmarked: false,
    className: '',
    currency_id: null,
    currency_symbol: null,
    price: null,
    price_text: null,
    is_trigger: false,
    shipping: null,
    squared: false,
  };

  WithItemBehavior.propTypes = {
    ...WithItemBehavior.itemPropTypes,
    metadata: metadataPropTypes.isRequired,
    restClient: PropTypes.shape({}).isRequired,
  };

  const exportItem = withTracker(WithItemBehavior);
  exportItem.metadataPropTypes = metadataPropTypes;
  return exportItem;
}

module.exports = withItemBehavior;
