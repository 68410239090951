const React = require('react');
const PropTypes = require('prop-types');

const Discovery = props => (
  <div className="column-discovery">
    <div className="column-discovery__title">{props.title}</div>
    <div className="column-discovery__wrapper">
      <div className="column-discovery__columns">
        {props.children}
      </div>
    </div>
  </div>
);

Discovery.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Discovery.defaultProps = {
  title: '',
};

module.exports = Discovery;
