const React = require('react');
const PropTypes = require('prop-types');

const RecommendationsContainer = require('../recommendations/recommendations-container');
const Item = require('../item');

const GridRecommendationsBase = require('./base');

class GridRecommendations extends GridRecommendationsBase {
  render() {
    return (
      <RecommendationsContainer {...this.props}>
        {this.grid.map(row => (
          <div className="grid-container">
            {row.map(item => <Item {...item} />)}
          </div>
        ))}
      </RecommendationsContainer>
    );
  }
}

GridRecommendations.propTypes = {
  carousel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  itemsPerRow: PropTypes.number.isRequired,
};

module.exports = GridRecommendations;
