const React = require('react');
const PropTypes = require('prop-types');

const Tooltip = require('@andes/tooltip');

const EditableButtons = require('../appearance/EditableButtons');
const Editable = require('../appearance/Editable').default;

const renderMe = () => true;

const createEditable = (Base, getProps, renderWrapper = renderMe, renderBase = renderMe) => {
  const Wrapper = (props) => {
    const { hidden, i18n } = props;
    const wrapperRef = React.createRef();
    const editableProps = getProps(props);
    const editButton = {
      label: hidden ? i18n.gettext('Activar sección') : i18n.gettext('Editar'),
      ...editableProps,
    };

    const tooltip = {
      title: i18n.gettext('Sección inactiva'),
      content: i18n.gettext('Edita esta sección para activarla en tu tienda.'),
      className: 'tooltip--editable',
      side: 'top',
      autoHideDelay: 2000,
    };

    if (!renderWrapper(props)) {
      return renderBase(props) ? <Base {...props} /> : null;
    }

    return (
      <div className="editable-wrapper editable-wrapper--with-border wrapper--editable" ref={wrapperRef}>
        <Editable type={editButton.type} payload={editButton.payload} wrapperRef={wrapperRef}>
          { hidden
            ? (
              <Tooltip {...tooltip}>
                <Base {...props} />
              </Tooltip>
            )
            : <Base {...props} />}
          <EditableButtons editButton={editButton} showDelete={false} />
        </Editable>
      </div>
    );
  };

  Wrapper.propTypes = {
    hidden: PropTypes.bool,
    i18n: PropTypes.shape(),
  };

  Wrapper.defaultProps = {
    hidden: false,
    i18n: { gettext: f => f },
  };

  return Wrapper;
};

module.exports = createEditable;
