const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const FakeContainer = ({ type }) => {
  const imageClass = classNames(
    'label-image',
    'label-image--fake',
    `label-image--${type}`,
    'label-image--behavior-show',
  );
  const labelClass = classNames('label-image__text', 'label-image__text--dark');
  return (
    <div className={imageClass}>
      <div className="label-image__background">
        <div className={labelClass} />
      </div>
    </div>
  );
};

FakeContainer.propTypes = {
  type: PropTypes.oneOf(['small', 'large']).isRequired,
};

module.exports = FakeContainer;
