const React = require('react');
const PropTypes = require('prop-types');
const CarouselPaged = require('ui-library_ml/ui/carousel');

const Slide = require('../slide');
const metadataPropType = require('../metadataProvider/metadataPropType');
const { defaultCarouselSettings, SLIDER_SIZE } = require('./config');

const classes = require('../../utils/customBEMHelper')({ name: 'slider' });

const Slider = (props, context) => {
  const { deviceType } = context.metadata;
  const carouselSettings = {
    ...defaultCarouselSettings,
    Slide,
    arrows: deviceType !== 'mobile',
    autoplay: props.autoplay.enable,
    dots: !context.metadata.lowEnd,
    speed: props.autoplay.time,
    infinite: deviceType === 'desktop',
  };

  const slides = props.slides.map(slide => ({
    ...slide,
    sliderSize: SLIDER_SIZE[deviceType][props.size],
  }));

  return (
    <div className={classes('', props.size)}>
      { (props.slides.length === 1)
        ? <div className="carousel-container"><Slide {...slides[0]} /></div>
        : <CarouselPaged {...carouselSettings} slides={slides} />}
    </div>
  );
};

Slider.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  slides: PropTypes.arrayOf(PropTypes.object),
  autoplay: PropTypes.shape({
    enable: PropTypes.bool,
    time: PropTypes.number,
  }).isRequired,
};

Slider.defaultProps = {
  slides: [],
};

Slider.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = Slider;
