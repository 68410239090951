const PropTypes = require('prop-types');
const React = require('react');
const InfoSlide = require('./info-slide');
const Section = require('../section');
const withTracker = require('../with-tracker');

function SiteShoppingInfoDesktop(props) {
  return (
    <Section type={props.type}>
      <div className="container">
        {props.elements.map(element => (
          <InfoSlide key={element.title_label} {...element} lazyLoad={props.lazyLoad} />
        ))}
      </div>
    </Section>
  );
}

SiteShoppingInfoDesktop.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title_label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
};

module.exports = withTracker(SiteShoppingInfoDesktop);
