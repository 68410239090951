const React = require('react');
const PropTypes = require('prop-types');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const { FAKE_CONTAINER_PRODUCTS } = require('../../utils/constants/tabbedCarousel');

const WrapperEditable = (Base) => {
  const Wrapper = (props, context) => {
    const { hidden, tabs } = props;
    const { deviceType } = context.metadata;
    const isNOC = hidden || (tabs.length === 0);
    const fakeItems = new Array(FAKE_CONTAINER_PRODUCTS[deviceType]).fill({});
    return isNOC
      ? (
        <div className="tabbed-carousel--fake">
          { fakeItems.map(() => <div className="tabbed-carousel__fake-item" />)}
        </div>
      )
      : <Base {...props} />;
  };

  Wrapper.propTypes = {
    hidden: PropTypes.bool.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
  };

  Wrapper.contextTypes = {
    metadata: metadataPropType,
  };

  return Wrapper;
};

module.exports = WrapperEditable;
