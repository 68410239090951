/**
 * Module dependencies
 */
const React = require('react');
const Action = require('./action');
const Image = require('./image-desktop');
const Shipping = require('./shipping');
const PriceBlock = require('./price-block');
const Review = require('./review');
const Props = require('./props');

// eslint-disable-next-line
const goToBrand = url => (window.location = url);

/**
 * Item Component
 */
const Item = (props) => {
  const Element = props.url ? 'a' : 'div';

  let classNames = 'ui-item';
  if (props.className) classNames += ` ${props.className}`;

  return (
    <Element
      href={props.url}
      className={classNames}
      itemScope
      itemType="http://schema.org/Product"
      title={props.showTitle ? props.title : null}
    >
      {/**
       * Image: supports retina sizes
       */}
      <Image {...props.image} size={props.imgSize} title={props.title} lazyLoad={props.lazyLoad} />
      <div className="ui-item__content">
        {/**
         * Shipping: Truck icon + free shipping text
         */}
        <Shipping shipping={props.shipping} />
        {/**
         * Price block: Main price, discount (original price + discount tag), installments
         */}
        {props.price || props.discount || props.installments ? (
          <PriceBlock price={props.price} discount={props.discount} installments={props.installments} />
        ) : null}
        {/**
         * Attributes as text. For example: "2014 | 60000 km"
         */}
        {props.attributes ? <span className="ui-item__attributes">{props.attributes}</span> : null}
        {/**
         * Title
         */}
        {props.title ? (
          <p className="ui-item__title" itemProp="name" ref={props.titleRef}>
            {props.title}
          </p>
        ) : null}
        {/**
         * Brand (Tienda oficial)
         */}
        {props.brand ? (
          <button
            className="ui-item__brand"
            onClick={(e) => {
              e.preventDefault();
              goToBrand(props.brand.url);
            }}
          >
            {props.brand.text}
          </button>
        ) : null}
        {/**
         * Review
         */}
        {props.review ? (
          <Review className="ui-item__review" stars={props.review.stars} total={props.review.total} />
        ) : null}

        {props.actions.map(action => <Action key={action.id} {...action} />)}
      </div>
      {/**
       * Metadata: see schema.org
       */}
      {props.url ? <meta itemProp="url" content={props.url} /> : null}
      {props.condition ? <meta itemProp="itemCondition" content={props.condition} /> : null}
      {props.id ? <meta itemProp="productID" content={props.id} /> : null}
    </Element>
  );
};

/**
 * Prop types and defaults
 */
Item.propTypes = Props.propTypes;
Item.defaultProps = Props.defaultProps;

/**
 * Expose Item
 */
module.exports = Item;
