const types = require('../Editable/types');

const getBannerProps = (props) => {
  const { componentId, styles, link, content } = props;
  return {
    type: types.SPLINTER_BANNER,
    payload: {
      componentId,
      desktopImageUrl: styles.bg_desktop,
      mobileImageUrl: styles.bg_mobile,
      description: content.title,
      textAlignment: content.align,
      linkUrl: link.url,
      linkText: content.link_text,
    },
  };
};

const getCarouselProps = (props) => {
  const { componentId, title, shape, variations, categories } = props;
  return {
    type: types.CAROUSEL_SPLINTER[variations],
    payload: { componentId, title, shape, categories },
  };
};

const isGridBannerFather = (props) => {
  const firstChildren = props.children[0];
  return firstChildren && (
    firstChildren.props.node.component === 'BannerSplinter'
    || firstChildren.props.node.component === 'BannerSplinterEditable'
  );
};

const getGridBannerEditableProps = (props) => {
  if (isGridBannerFather(props)) {
    const bannerNode = props.children[0].props.node;
    const { id } = bannerNode;
    const { styles, link, content } = bannerNode.properties;
    return {
      type: types.SPLINTER_BANNER,
      payload: {
        componentId: id,
        desktopImageUrl: styles.bg_desktop,
        mobileImageUrl: styles.bg_mobile,
        description: content.title,
        textAlignment: content.align,
        linkUrl: link.url,
        linkText: content.link_text,
      },
    };
  }

  return {};
};

module.exports = { getBannerProps, getCarouselProps, isGridBannerFather, getGridBannerEditableProps };
