const React = require('react');
const PropTypes = require('prop-types');

const createEditable = require('../../adapters/createEditable');

const Discovery = require('./index');
const FakeContainer = require('./fakeContainer');

const { EDITABLE_FAKE_CHILDREN, getDiscoveryProps } = require('./utils');

const Wrapper = (props) => {
  if (props.hidden || !props.children.length) {
    return (
      <div className="column-discovery column-discovery--hidden">
        <div className="column-discovery__title">{props.title}</div>
        <div className="column-discovery__wrapper">
          <div className="column-discovery__columns">
            { EDITABLE_FAKE_CHILDREN.map(fake => <FakeContainer {...fake} />) }
          </div>
        </div>
      </div>
    );
  }

  return <Discovery {...props} />;
};

Wrapper.propTypes = {
  title: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

module.exports = createEditable(Wrapper, getDiscoveryProps);
