const { Components } = require('splinter-ui/lib/all');

const splinterMapper = require('../../adapters/splinterMapper');

const createBannerEditable = require('./banner.editable');
const createCarouselEditable = require('./carousel.editable');
const createGridEditable = require('./grid.editable');

module.exports = {
  nonEditables: {
    BannerSplinter: splinterMapper(Components.Banner),
    Card: splinterMapper(Components.Card),
    Carousel: splinterMapper(Components.Carousel),
    Grid: splinterMapper(Components.Grid),
    Subtitle: splinterMapper(Components.Subtitle),
  },
  editables: {
    BannerSplinterEditable: createBannerEditable(splinterMapper(Components.Banner)),
    CardEditable: splinterMapper(Components.Card),
    CarouselEditable: createCarouselEditable(splinterMapper(Components.Carousel)),
    GridEditable: createGridEditable(splinterMapper(Components.Grid)),
  },
};
