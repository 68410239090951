/**
 * Module dependencies
 */
const React = require('react');
const Price = require('loki-price');
const PropTypes = require('prop-types');

/**
 * PriceBlock Component
 */
const PriceBlock = ({ price, discount, installments }) => (
  <div className="ui-item__price-block">
    {price.text ?
      <span className="ui-item__price">{price.text}</span> : null}
    {/**
     * Discount price: disabled if an original_price exists
     */}
    {discount ? <Price amount={discount.original_price} className="ui-item__discount-price" disabled /> : null}
    {/**
     * The main price of the item
     */}
    {price.currency_id ? <Price amount={price} className="ui-item__price" /> : null}
    {/**
     * Discount percentage tag with the diff between original_price and price. For example: "25% OFF"
     */}
    {discount ? <span className="ui-item__discount-text">{discount.text}</span> : null}
    {/**
     * Installments as text. For example: "6x $5.000 sin interés"
     */}
    {installments ? <span className="ui-item__installments">{installments}</span> : null}
  </div>
);

PriceBlock.propTypes = {
  price: PropTypes.object.isRequired,
  discount: PropTypes.object,
  installments: PropTypes.string,
};

PriceBlock.defaultProps = {
  discount: null,
  installments: null,
};

/**
 * Expose PriceBlock
 */
module.exports = PriceBlock;
