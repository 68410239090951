const React = require('react');

const { LandingProvider } = require('splinter-ui/lib/all');

const { splinterConverter } = require('./converter');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const splinterMapper = (Base) => {
  const Mapper = (props, context) => (
    <LandingProvider
      landingData={{ components: [splinterConverter(props)] }}
      webp={false}
      siteId={context.metadata.siteId}
      deviceType={context.metadata.deviceType}
      lazyLoad={{ enabled: false }}
    >
      <Base props={props} index={0} />
    </LandingProvider>
  );

  Mapper.contextTypes = {
    ...Base.contextTypes,
    metadata: metadataPropType.isRequired,
  };

  return Mapper;
};

module.exports = splinterMapper;
