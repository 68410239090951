const { pathOr } = require('ramda');
const { defaultComponent, nonEditables, editables } = require('./componentList');

function findByName(name) {
  if (name in editables) {
    return editables[name];
  }

  return nonEditables[name.replace(/Editable$/, '')] || defaultComponent;
}

function isComponentType(obj) {
  return typeof obj === 'function';
}

function applyDisplayName(base, displayName) {
  return pathOr(
    defaultComponent,
    ['displayAs', displayName],
    base,
  );
}

function getComponent(componentName, deviceType = null, displayName = null) {
  const result = findByName(componentName);

  if (isComponentType(result)) {
    return result;
  }

  const tentativeComponent = displayName ? applyDisplayName(result, displayName) : result;

  if (isComponentType(tentativeComponent)) {
    return tentativeComponent;
  }

  return tentativeComponent[deviceType] || defaultComponent;
}

module.exports = { getComponent };
