const React = require('react');
const PropTypes = require('prop-types');
const { pathOr } = require('ramda');
const uuid = require('uuid/v5');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const { getComponent } = require('./componentMapper');

class RenderTree extends React.Component {
  constructor(props, context) {
    super(props, context);
    const { deviceType } = context.metadata;
    this.Component = getComponent(
      props.node.component,
      deviceType,
      pathOr(null, ['node', 'properties', 'display'], props),
    );
  }

  render() {
    const { i18n, node } = this.props;
    const { Component } = this;
    const properties = node.properties || {};
    const children = node.children || [];

    // Editable components needs to send his id to admin in order to allow customization
    properties.componentId = node.id;
    // @FIXME we create a dummy RestClient for now, since we don't need it
    properties.restClient = { _dummy: true };
    // We add this properties for splinter components
    properties.name = node.component;
    properties.nodeItems = children;
    properties.nodeStyles = node.properties && node.properties.styles;
    // We need hidden prop for NOC components
    properties.hidden = node.hidden;

    return (
      <Component {...properties} i18n={i18n}>
        {children.map((child, index) => (
          <RenderTree
            key={uuid(node.component + index, uuid.DNS)}
            node={child}
            i18n={i18n}
          />
        ))}
      </Component>
    );
  }
}

// Recursive treeShape
const node = {
  component: PropTypes.string,
  properties: PropTypes.object,
};

RenderTree.contextTypes = {
  metadata: metadataPropType.isRequired,
};

RenderTree.propTypes = {
  i18n: PropTypes.shape({ gettext: PropTypes.func }),
  node: PropTypes.shape({ children: PropTypes.arrayOf(PropTypes.shape(node)), ...node }).isRequired,
};

RenderTree.defaultProps = {
  i18n: { gettext: f => f },
  uniqueId: 0,
};

module.exports = RenderTree;
