const types = require('../Editable/types');

const getCarouselProps = (props) => {
  const { componentId, title, id, values, display, width } = props;
  return {
    type: types.CAROUSEL,
    payload: { componentId, title, id, values, display, width },
  };
};

// We explicitly say that length must be greater than 0 to avoid strange javascript type casting
const renderWrapper = props => props.items && (props.items.length > 0);

module.exports = { getCarouselProps, renderWrapper };
