/* eslint-env browser */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const metadataPropType = require('../metadataProvider/metadataPropType');

const classes = require('../../utils/customBEMHelper')({ name: 'slide' });

const DESKTOP_DEFAULT_WIDTH = 1920;
const MOBILE_DEFAULT_WIDTH = 360;
const TOLERANCE = 1000;

class SlideImage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.onWindowResize = this.onWindowResize.bind(this);
    this.shouldAdjustHeight = this.shouldAdjustHeight.bind(this);

    this.state = {
      adjustHeight: this.shouldAdjustHeight(),
      hasMounted: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);

    /**
     * This set state should not be used here, because we are
     * triggering an extra rendering.
     * But we are dealing with a special case, in which the
     * client code could be different from the server side,
     * since its depends from the browser width.
     * Therefore, we require to use this method here.
     */
    this.setState({ hasMounted: true }, this.onWindowResize); // eslint-disable-line react/no-did-mount-set-state
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize() {
    this.setState({
      adjustHeight: this.shouldAdjustHeight(),
    });
  }

  shouldAdjustHeight() {
    const { deviceType } = this.context.metadata;
    const { image, sliderSize } = this.props;
    const { hasMounted } = this.state || {};

    const defaultWidth = (deviceType === 'desktop') ? DESKTOP_DEFAULT_WIDTH : MOBILE_DEFAULT_WIDTH;
    const baseWidth = (hasMounted && typeof window === 'object') ? window.innerWidth : defaultWidth;

    const imageRatio = Math.round((image.height / image.width) * TOLERANCE);
    const sliderRatio = Math.round((sliderSize / baseWidth) * TOLERANCE);

    return imageRatio < sliderRatio;
  }

  render() {
    const { image, text_alignment: textAlignment, heading, title, subtitle } = this.props;
    const { deviceType } = this.context.metadata;

    const imageAdjustedClass = classNames(classes('image'), {
      'image-adjust-height': this.state.adjustHeight,
      'image-adjust-width': !this.state.adjustHeight,
    });

    const imageMobile = image.url_alternative ? image.url_alternative : image.url;
    const imageStyles = {
      backgroundImage: `url("${(deviceType === 'mobile') ? imageMobile : image.url}")`,
      backgroundPosition: image.alignment,
    };

    return (
      <div className={imageAdjustedClass} style={imageStyles}>
        <div className={classes('text', textAlignment)}>
          <h3 className={classes('heading')}>{heading}</h3>
          <h1 className={classes('title')}>{title}</h1>
          <h2 className={classes('subtitle')}>{subtitle}</h2>
        </div>
      </div>
    );
  }
}

SlideImage.propTypes = {
  sliderSize: PropTypes.number.isRequired,
  image: PropTypes.shape({
    alignment: PropTypes.oneOf(['top', 'center', 'bottom']).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  heading: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text_alignment: PropTypes.string.isRequired,
};

SlideImage.defaultProps = {
  heading: '',
  title: '',
  subtitle: '',
};

SlideImage.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = SlideImage;
