const types = require('../Editable/types');

const getPriceAndImageProps = (props) => {
  const {
    componentId,
    title,
    id,
    theme,
    card_theme: cardTheme,
    categories,
  } = props;

  return {
    type: types.PRICE_AND_IMAGE,
    payload: { componentId, title, id, theme, cardTheme, categories },
  };
};

module.exports = { getPriceAndImageProps };
