const PropTypes = require('prop-types');
const React = require('react');
const Image = require('../image');

function InfoSlide(props) {
  return (
    <div key={props.title_label} className="info-slide">
      <div className="img-container">
        <Image
          className="img-container"
          alt={props.title_label}
          src={props.picture_url}
          lazyLoad={props.lazyLoad}
        />
      </div>
      <h1 className={props.disclaimer ? 'info-slide__disclaimer' : ''}>{props.title_label}</h1>
      <p>{props.description}</p>
      { props.disclaimer
        ? (
          <p>
            <small className="info-slide__shipping-conditions">{props.disclaimer}</small>
          </p>
        ) : null}
      { props.target_url ? <a href={props.target_url}>{props.link_label}</a> : null }
    </div>
  );
}

InfoSlide.propTypes = {
  description: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  link_label: PropTypes.string.isRequired,
  picture_url: PropTypes.string.isRequired,
  target_url: PropTypes.string.isRequired,
  title_label: PropTypes.string.isRequired,
};

module.exports = InfoSlide;
